import {
  CheckCircleFilled,
  ScheduleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  prepareBedrockBatch,
  prepareBedrockRun,
  sendBedrockBatch,
  sendBedrockRun,
  useBedrockRuns,
} from '@client/BedrockConfigurationClient';
import {
  BedrockBatchToken,
  BedrockJournalSpec,
  BedrockMechanism,
  BedrockRunOpts,
  IBedrockRun,
} from '@shared/bedrock';
import { formatLongDate } from '@shared/formatLongDate';
import { IUser, OrganizationToken } from '@shared/types';
import { KnownBlock } from '@slack/types';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';
import { PageContent } from '@web/app/Page';
import { successColor } from '@web/app/styles/ColorStyles';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, Header3, Text } from '@web/components/typography';
import { Button, Divider, InputNumber, Modal, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { BedrockTabs } from './BedrockTabs';
import { SelectMechanism } from './SelectMechanism';

export const BedrockBatchPage: React.FC = () => {
  const { organizationToken, batchToken } = useParams<{
    organizationToken: OrganizationToken;
    batchToken: BedrockBatchToken;
  }>();

  const { data: response, mutate: reloadRuns } = useBedrockRuns(
    organizationToken,
    batchToken,
  );
  const [showPrepareModal, setShowPrepareModal] = React.useState(false);
  const [showSendModal, setShowSendModal] = React.useState(false);

  const handlePrepareRun = async (
    run: IBedrockRun,
    { runOpts, mechanism }: PrepareRunFormData,
  ) => {
    try {
      await prepareBedrockRun(
        organizationToken,
        batchToken,
        run.token,
        mechanism,
        runOpts,
      );
      await reloadRuns();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendRun = async (
    run: IBedrockRun,
    mechanism: BedrockMechanism,
  ) => {
    try {
      await sendBedrockRun(organizationToken, batchToken, run.token, mechanism);
      await reloadRuns();
      setShowSendModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handlePrepareBatch = async ({
    runOpts,
    mechanism,
  }: PrepareRunFormData) => {
    try {
      await prepareBedrockBatch(
        organizationToken,
        batchToken,
        mechanism,
        runOpts,
      );
      await reloadRuns();
      setShowPrepareModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendBatch = async (mechanism: BedrockMechanism) => {
    try {
      await sendBedrockBatch(organizationToken, batchToken, mechanism);
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <PageContent>
      <PageHeader title="Bedrock" />
      <BedrockTabs />
      <Pane>
        <Column gap={24}>
          <Column gap={12}>
            <Row>
              <Header2>Runs</Header2>
              <GrowingSpacer />
              <Row gap={6}>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowPrepareModal(true);
                  }}
                >
                  <ScheduleOutlined /> Prepare
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowSendModal(true);
                  }}
                >
                  <SendOutlined /> Send
                </Button>
              </Row>
            </Row>
            <BedrockRunsTable
              runs={response?.runs}
              onPrepareRun={handlePrepareRun}
              onSendRun={handleSendRun}
            />
          </Column>
        </Column>
      </Pane>
      <PrepareRunModal
        open={showPrepareModal}
        onCancel={() => {
          setShowPrepareModal(false);
        }}
        onSubmit={handlePrepareBatch}
      />
      <SelectMechanismModal
        title="Send Batch"
        okText="Send"
        open={showSendModal}
        onCancel={() => {
          setShowSendModal(false);
        }}
        onSubmit={handleSendBatch}
      />
    </PageContent>
  );
};

export const BlockPreviewCell: React.FC<{ blocks?: KnownBlock[] }> = ({
  blocks,
}) => {
  const [showPreview, setShowPreview] = React.useState(false);

  if (!blocks) {
    return undefined;
  }
  const json = JSON.stringify(blocks, ' ' as any, 2).trim();
  const closeModal = () => {
    setShowPreview(false);
  };
  return (
    <Column>
      <CheckCircleFilled
        onClick={() => {
          setShowPreview(true);
        }}
        style={{ color: successColor.string(), alignSelf: 'center' }}
      />
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <CodeMirror value={json} height="500px" theme={vscodeDark} />
      </Modal>
    </Column>
  );
};

export const JournalPreviewCell: React.FC<{
  journals?: BedrockJournalSpec[];
}> = ({ journals }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const closeModal = () => {
    setShowPreview(false);
  };
  if (!journals) {
    return undefined;
  }

  return (
    <Column gap={24}>
      <Text
        onClick={
          journals.length > 0
            ? () => {
                setShowPreview(true);
              }
            : undefined
        }
        style={{ cursor: journals.length > 0 ? 'pointer' : undefined }}
      >
        {journals.length} entries
      </Text>
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <Column gap={12}>
          {journals.map((journal, index) => {
            return (
              <Column key={index} style={{ maxWidth: 824 }}>
                <Header3>{journal.title}</Header3>
                <Text>{formatLongDate(journal.date)}</Text>
                <Markdown value={journal.descriptionMarkdown} />
                {index !== journals.length - 1 ? <Divider /> : undefined}
              </Column>
            );
          })}
        </Column>
      </Modal>
    </Column>
  );
};

export const RawPreviewCell: React.FC<{
  raw?: string;
}> = ({ raw }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const json = raw ? JSON.stringify(JSON.parse(raw), ' ' as any, 2).trim() : '';

  const closeModal = () => {
    setShowPreview(false);
  };
  if (!raw) {
    return undefined;
  }

  return (
    <Column gap={24}>
      <CheckCircleFilled
        onClick={() => {
          setShowPreview(true);
        }}
        style={{ color: successColor.string(), alignSelf: 'center' }}
      />
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <CodeMirror value={json} height="500px" theme={vscodeDark} />
      </Modal>
    </Column>
  );
};

export interface PrepareRunFormData {
  runOpts: BedrockRunOpts;
  mechanism: BedrockMechanism;
}
export const BedrockRunsTable: React.FC<{
  runs?: IBedrockRun[];
  onPrepareRun: (
    run: IBedrockRun,
    formData: PrepareRunFormData,
  ) => Promise<void>;
  onSendRun: (run: IBedrockRun, mechanism: BedrockMechanism) => Promise<void>;
}> = ({ runs, onPrepareRun, onSendRun }) => {
  const columns: ColumnsType<IBedrockRun> = [
    {
      dataIndex: ['user'],
      title: '',
      render: (user: IUser) => <UserMessage user={user} />,
    },
    {
      dataIndex: 'journal',
      title: 'Journal',
      render: (journals?: BedrockJournalSpec[]) => {
        return <JournalPreviewCell journals={journals} />;
      },
    },
    {
      dataIndex: 'blocks',
      title: 'Blocks',
      render: (blocks?: KnownBlock[]) => {
        return <BlockPreviewCell blocks={blocks} />;
      },
    },
    {
      dataIndex: 'raw',
      title: 'Raw',
      render: (raw?: string) => {
        return <RawPreviewCell raw={raw} />;
      },
    },
    {
      key: 'actions',
      title: '',
      render: (_, run) => {
        return (
          <RowActions
            run={run}
            onPrepareRun={onPrepareRun}
            onSendRun={onSendRun}
          />
        );
      },
    },
  ];
  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={runs}
      loading={!runs}
      pagination={false}
    />
  );
};

const RowActions: React.FC<{
  run: IBedrockRun;
  onPrepareRun: (
    run: IBedrockRun,
    formData: PrepareRunFormData,
  ) => Promise<void>;
  onSendRun: (run: IBedrockRun, mechanism: BedrockMechanism) => Promise<void>;
}> = ({ run, onPrepareRun, onSendRun }) => {
  const [showPrepareModal, setShowPrepareModal] = React.useState(false);
  const [showSendModal, setShowSendModal] = React.useState(false);

  const handlePrepare = async (formData: PrepareRunFormData) => {
    try {
      await onPrepareRun(run, formData);
      setShowPrepareModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSend = async (mechanism: BedrockMechanism) => {
    try {
      await onSendRun(run, mechanism);
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Row gap={12}>
      <Button
        onClick={() => {
          setShowPrepareModal(true);
        }}
      >
        <ScheduleOutlined />
      </Button>
      <Button
        onClick={() => {
          setShowSendModal(true);
        }}
      >
        <SendOutlined />
      </Button>
      <SelectMechanismModal
        title="Send Run"
        okText="Send"
        open={showSendModal}
        onSubmit={handleSend}
        onCancel={() => {
          setShowSendModal(false);
        }}
      />
      <PrepareRunModal
        open={showPrepareModal}
        onSubmit={handlePrepare}
        onCancel={() => {
          setShowPrepareModal(false);
        }}
      />
    </Row>
  );
};

export const PrepareRunModal: React.FC<{
  open: boolean;
  onSubmit: (formData: PrepareRunFormData) => Promise<void>;
  onCancel: () => void;
}> = ({ open, onSubmit, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [adjacentMessageCount, setAdjacentMessageCount] = React.useState(2);
  const [maxEntryCount, setMaxEntryCount] = React.useState(10);
  const [suggestedEntryCount, setSuggestedEntryCount] = React.useState(5);
  const [mechanism, setMechanism] = React.useState<
    BedrockMechanism | undefined
  >(undefined);
  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      const formData: PrepareRunFormData = {
        runOpts: {
          suggestedEntryCount,
          maxEntryCount,
          adjacentMessageCount,
        },
        mechanism,
      };
      await onSubmit(formData);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  return (
    <Modal
      title="Prepared Run"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      okText="Prepare Run"
      afterClose={handleClose}
      onCancel={onCancel}
    >
      <Column gap={12}>
        <Column gap={6}>
          <Text>Maximum Entries</Text>
          <InputNumber
            disabled={isSubmitting}
            value={maxEntryCount}
            onChange={(count) => {
              setMaxEntryCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Suggested Entries</Text>
          <InputNumber
            disabled={isSubmitting}
            value={suggestedEntryCount}
            onChange={(count) => {
              setSuggestedEntryCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Adjacent Message Count</Text>
          <InputNumber
            disabled={isSubmitting}
            value={adjacentMessageCount}
            onChange={(count) => {
              setAdjacentMessageCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Mechanism</Text>
          <SelectMechanism disabled={isSubmitting} onChange={setMechanism} />
        </Column>
      </Column>
    </Modal>
  );
};

export const SelectMechanismModal: React.FC<{
  title: string;
  okText: string;
  open: boolean;
  onSubmit: (mechanism: BedrockMechanism) => void;
  onCancel: () => void;
}> = ({ title, okText, open, onSubmit, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [mechanism, setMechanism] = React.useState<
    BedrockMechanism | undefined
  >(undefined);

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(mechanism);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={title}
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSubmitting}
      onCancel={onCancel}
      width="500px"
      okText={okText}
      okButtonProps={{
        disabled: isSubmitting || !mechanism,
      }}
    >
      <Column gap={6}>
        <Text>Mechanism</Text>
        <SelectMechanism disabled={isSubmitting} onChange={setMechanism} />
      </Column>
    </Modal>
  );
};
