import { possessive } from '@backend/common/possessive';
import { IImpact } from '@shared/impacts';
import { IQuestionResponse } from '@shared/question_response';
import {
  QuestionToken,
  impactOpportunityQuestion,
  impactStrengthQuestion,
} from '@shared/questions';
import {
  FeedbackVisibility,
  IDraftResponse,
  IFeedback,
  TaskToken,
} from '@shared/types';
import { isManagerOf } from '@shared/users';
import { IF_MOBILE } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { SelectVisibility } from '@web/components/SelectVisibility';
import { UserMessage } from '@web/components/UserMessage';
import {
  Column,
  GrowingSpacer,
  ResponsiveRow,
  Row,
  Spacer,
} from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { AutoSaveLabel, AutoSaveState } from '@web/reflections/AutoSaveState';
import { Button, Skeleton } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

export const EntryFeedbackFormFields: React.FC<{
  assignedTaskToken: TaskToken;
  feedback: IFeedback | null;
  impactEntry: IImpact;
  disabled: boolean;
  showDecline: boolean;
  onSave: (feedback: IQuestionResponse[]) => void;
  onCancel?: () => void;
  onDecline: () => void;
}> = ({
  assignedTaskToken,
  feedback,
  impactEntry,
  disabled,
  showDecline,
  onSave,
  onCancel,
  onDecline,
}) => {
  const { user } = useAuth();
  const [autoSaveState, setAutoSaveState] = useState<AutoSaveState>(null);
  const [lastAutoSave, setLastAutoSave] = useState<Date>(null);
  const {
    existingOpportunityFeedback,
    existingStrengthFeedback,
    hasExistingFeedback,
  } = extractExistingFeedback(feedback);
  const isAuthorManager = isManagerOf(impactEntry.user, user.token);
  const autosavingEnabled = !hasExistingFeedback && assignedTaskToken;
  const { data: draftResponses, mutate: invalidateDraftResponses } = useApi<
    IDraftResponse[]
  >(
    autosavingEnabled
      ? `/feedback_requests/${assignedTaskToken}/draft_responses`
      : null,
  );

  const [strengthQuestionResponse, setStrengthQuestionResponse] =
    React.useState(existingStrengthFeedback?.text ?? '');
  const [strengthQuestionVisibility, setStrengthQuestionVisibility] =
    React.useState(
      existingStrengthFeedback?.visibility ??
        FeedbackVisibility.RECEIVER_MANAGER,
    );
  const [opportunityQuestionResponse, setOpportunityQuestionResponse] =
    React.useState(existingOpportunityFeedback?.text ?? '');
  const [opportunityQuestionVisibility, setOpportunityQuestionVisibility] =
    React.useState(
      existingOpportunityFeedback?.visibility ??
        FeedbackVisibility.RECEIVER_MANAGER,
    );

  const isLoadingDrafts = autosavingEnabled && !draftResponses;

  useEffect(() => {
    if (!draftResponses) {
      return;
    }

    const draftStrengthResponse = draftResponses.find(
      (response) => response.questionToken === impactStrengthQuestion.token,
    );
    if (draftStrengthResponse) {
      setStrengthQuestionResponse(draftStrengthResponse.text);
    }
    const draftStrengthVisibility = draftResponses.find(
      (response) =>
        response.questionToken === `${impactStrengthQuestion.token}_visibility`,
    );
    if (draftStrengthVisibility) {
      setStrengthQuestionVisibility(draftStrengthVisibility.text as any);
    }
    const draftOpportunityResponse = draftResponses.find(
      (response) => response.questionToken === impactOpportunityQuestion.token,
    );
    if (draftOpportunityResponse) {
      setOpportunityQuestionResponse(draftOpportunityResponse.text);
    }
    const draftOpportunityVisibility = draftResponses.find(
      (response) =>
        response.questionToken ===
        `${impactOpportunityQuestion.token}_visibility`,
    );
    if (draftOpportunityVisibility) {
      setOpportunityQuestionVisibility(draftOpportunityVisibility.text as any);
    }
  }, [isLoadingDrafts]);

  const handleAutosave = useMemo(
    () =>
      debounce(async (questionToken: QuestionToken, text: string) => {
        try {
          setAutoSaveState('loading');
          await put<IDraftResponse>(
            `/feedback_requests/${assignedTaskToken}/draft_responses`,
            { questionToken, text },
          );
          void invalidateDraftResponses();
          setAutoSaveState('success');
          setLastAutoSave(new Date());
        } catch (error) {
          setAutoSaveState('error');
        }
      }, 500),
    [],
  );

  if (isLoadingDrafts) {
    return <Skeleton />;
  }

  const handleSave = () => {
    const responses = [];
    if (strengthQuestionResponse || existingStrengthFeedback) {
      if (existingStrengthFeedback) {
        responses.push({
          token: existingStrengthFeedback.token,
          questionToken: impactStrengthQuestion.token,
          text: strengthQuestionResponse || null,
          visibility: strengthQuestionVisibility,
        });
      } else {
        responses.push({
          questionToken: impactStrengthQuestion.token,
          text: strengthQuestionResponse,
          visibility: strengthQuestionVisibility,
        });
      }
    }
    if (opportunityQuestionResponse || existingOpportunityFeedback) {
      if (existingOpportunityFeedback) {
        responses.push({
          token: existingOpportunityFeedback.token,
          questionToken: impactOpportunityQuestion.token,
          text: opportunityQuestionResponse || null,
          visibility: opportunityQuestionVisibility,
        });
      } else {
        responses.push({
          questionToken: impactOpportunityQuestion.token,
          text: opportunityQuestionResponse,
          visibility: opportunityQuestionVisibility,
        });
      }
    }
    onSave(responses);
  };

  const handleStrengthResponseChanged = (text: string) => {
    setStrengthQuestionResponse(text);
    void handleAutosave(impactStrengthQuestion.token, text);
  };
  const handleStrengthVisibilityChanged = (visibility: FeedbackVisibility) => {
    setStrengthQuestionVisibility(visibility);
    void handleAutosave(
      `${impactStrengthQuestion.token}_visibility`,
      visibility,
    );
  };
  const handleOpportunityResponseChanged = (text: string) => {
    setOpportunityQuestionResponse(text);
    void handleAutosave(impactOpportunityQuestion.token, text);
  };
  const handleOpportunityVisibilityChanged = (
    visibility: FeedbackVisibility,
  ) => {
    setOpportunityQuestionVisibility(visibility);
    void handleAutosave(
      `${impactOpportunityQuestion.token}_visibility`,
      visibility,
    );
  };

  return (
    <UserMessage user={user} fontSize={12} avatarSize={24}>
      <Column style={{ flex: 1 }}>
        <Row>
          <Header3>{impactStrengthQuestion.text}</Header3>
          <GrowingSpacer />
        </Row>
        <Spacer size={10} />
        <TextArea
          value={strengthQuestionResponse}
          onChange={(e) => {
            handleStrengthResponseChanged(e.currentTarget.value);
          }}
          style={{ minHeight: 120 }}
        />
        {!isAuthorManager && (
          <SelectVisibility
            userName={impactEntry.user.name}
            value={strengthQuestionVisibility}
            onChange={handleStrengthVisibilityChanged}
            style={{ alignSelf: 'flex-start' }}
          />
        )}
        <Spacer size={12} />
        <Row>
          <Header3>{impactOpportunityQuestion.text}</Header3>
          <GrowingSpacer />
        </Row>

        <Spacer size={10} />
        <TextArea
          value={opportunityQuestionResponse}
          onChange={(e) => {
            handleOpportunityResponseChanged(e.currentTarget.value);
          }}
          style={{ minHeight: 120 }}
        />
        {!isAuthorManager && (
          <SelectVisibility
            userName={impactEntry.user.name}
            value={opportunityQuestionVisibility}
            onChange={handleOpportunityVisibilityChanged}
            style={{ alignSelf: 'flex-start' }}
          />
        )}
        <Spacer size={12} />
        <ResponsiveRow gap={12}>
          <FormButtons>
            <Button
              disabled={disabled}
              type="primary"
              onClick={() => {
                handleSave();
              }}
            >
              {hasExistingFeedback ? 'Update' : 'Give'} feedback
            </Button>
            {showDecline ? (
              <ConfirmButton
                description={`Are you sure you wish to decline giving feedback on ${possessive(
                  impactEntry.user.name,
                )} work?`}
                onConfirm={onDecline}
                disabled={disabled}
              >
                Decline
              </ConfirmButton>
            ) : onCancel ? (
              <Button disabled={disabled} onClick={onCancel}>
                Cancel
              </Button>
            ) : null}
          </FormButtons>
          <AutoSaveLabel saveDate={lastAutoSave} lastState={autoSaveState} />
        </ResponsiveRow>
      </Column>
    </UserMessage>
  );
};

const FormButtons = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  gap: 10px;

  ${IF_MOBILE} {
    max-width: 100vw;
  }
`;

const extractExistingFeedback = (feedback: IFeedback | null) => {
  const responses = feedback?.responses;
  const existingStrengthFeedback = responses?.find(
    (f) => f.questionToken === impactStrengthQuestion.token,
  );
  const existingOpportunityFeedback = responses?.find(
    (f) => f.questionToken === impactOpportunityQuestion.token,
  );
  const hasExistingFeedback =
    !!existingStrengthFeedback || !!existingOpportunityFeedback;

  return {
    existingOpportunityFeedback,
    existingStrengthFeedback,
    hasExistingFeedback,
  };
};
