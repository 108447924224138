import { OrganizationToken } from '@shared/types';
import { TabLink, Tabs } from '@web/components/Tabs';
import React from 'react';
import { useParams } from 'react-router-dom';

export const BedrockTabs: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  return (
    <Tabs>
      <TabLink to={`/organizations/${organizationToken}/bedrock/batches`}>
        Batches
      </TabLink>
      <TabLink to={`/organizations/${organizationToken}/bedrock/config`}>
        Config
      </TabLink>
    </Tabs>
  );
};
