import { CommentOutlined } from '@ant-design/icons';
import {
  IFeedbackTemplate,
  TemplateParameters,
} from '@shared/feedbackTemplates';
import { IQuestion, QuestionToken, isQuestionLabel } from '@shared/questions';
import { UserMapItem } from '@shared/types';
import { useResponsive } from '@web/app/responsive';
import { BlockQuote } from '@web/components/BlockQuote';
import { FormButtons } from '@web/components/FormButtons';
import { UserMessage } from '@web/components/UserMessage';
import { Column, ResponsiveRow, Spacer } from '@web/components/layout';
import { renderReceiverQuestionText } from '@web/questions/renderReceiverQuestionText';
import { AutoSaveLabel, AutoSaveState } from '@web/reflections/AutoSaveState';
import { Button, Divider, Form, Typography, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { debounce, sortBy } from 'lodash';
import React, { useState } from 'react';

import { runTemplate } from './templatedFeedback';

interface FeedbackRequestFormProps {
  template: IFeedbackTemplate;
  parameters: Record<string, string>;
  receiver?: UserMapItem;
  isViewing: boolean;
  disabled: boolean;
  initialValues?: RequestedFeedbackFields;
  isReviewCycleRequest?: boolean;
  onSubmit?: (fields: RequestedFeedbackFields) => void;
  onCancel?: () => void;
  onResponseChange?: (
    questionToken: QuestionToken,
    value: string,
  ) => Promise<any>;
}

export interface RequestedFeedbackFields {
  feedbackResponses: Record<string, string | undefined>;
}

export const TemplatedFeedbackForm: React.FC<FeedbackRequestFormProps> = ({
  initialValues,
  isViewing,
  disabled,
  template,
  parameters,
  onSubmit,
  onCancel,
  receiver,
  isReviewCycleRequest = false,
  onResponseChange,
}: FeedbackRequestFormProps) => {
  const { isMobile } = useResponsive();
  const [autoSaveState, setAutoSaveState] = useState<AutoSaveState>(null);
  const [lastAutoSave, setLastAutoSave] = useState<Date | null>(null);
  const [form] = Form.useForm<RequestedFeedbackFields>();
  const handleSubmit = async () => {
    let fields: RequestedFeedbackFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      return;
    }
    if (onSubmit) {
      const responses = Object.values(fields.feedbackResponses).filter(
        (response) => !!response,
      );
      if (responses.length === 0) {
        void message.error('At least one response is required');
        return;
      }
      onSubmit(fields);
    }
  };
  const handleAutosave = (question: IQuestion) =>
    debounce(async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!onResponseChange) {
        return;
      }

      try {
        setAutoSaveState('loading');
        await onResponseChange(question.token, e.target.value);
        setAutoSaveState('success');
        setLastAutoSave(new Date());
      } catch (error) {
        setAutoSaveState('error');
      }
    }, 500);
  const questions = sortBy(template.feedbackQuestions, (q1) =>
    template.feedbackQuestions.findIndex((q2) => q1.token === q2.token),
  );

  return (
    <Column>
      {receiver && (
        <UserMessage user={receiver} large style={{ marginBottom: 24 }} />
      )}
      <FeedbackHeader template={template} parameters={parameters} />
      <Spacer />
      <Form
        form={form}
        initialValues={initialValues ?? {}}
        layout="vertical"
        disabled={disabled}
      >
        {questions.map((question) => {
          if (isQuestionLabel(question)) {
            return (
              <Column key={question.token} style={{ maxWidth: 800 }}>
                <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
                  {question.text}
                </Typography.Text>
                <Divider />
              </Column>
            );
          }

          return (
            <Form.Item
              key={question.token}
              name={['feedbackResponses', question.token]}
              label={runTemplate(
                renderReceiverQuestionText(question, receiver?.name),
                parameters,
              )}
              rules={
                question.required
                  ? [{ required: true, message: 'Response is required' }]
                  : undefined
              }
            >
              <TextArea
                disabled={disabled}
                onChangeCapture={handleAutosave(question)}
              />
            </Form.Item>
          );
        })}
      </Form>
      <Typography.Text style={{ fontStyle: 'italic' }}>
        {isReviewCycleRequest
          ? runTemplate(
              'Note: Your feedback will be visible to {receiver} and their manager',
              parameters,
            )
          : runTemplate(
              "Note: Your feedback will only be visible to {receiver}'s manager",
              parameters,
            )}
      </Typography.Text>
      <Spacer size={12} />
      {!isViewing ? (
        <ResponsiveRow gap={12}>
          <FormButtons
            style={{ width: isMobile ? '100%' : undefined, marginTop: 0 }}
          >
            <Button disabled={disabled} type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button disabled={disabled} onClick={onCancel}>
              Cancel
            </Button>
          </FormButtons>
          {lastAutoSave && (
            <AutoSaveLabel saveDate={lastAutoSave} lastState={autoSaveState} />
          )}
        </ResponsiveRow>
      ) : undefined}
    </Column>
  );
};

const FeedbackHeader: React.FC<{
  template: IFeedbackTemplate;
  parameters: TemplateParameters;
}> = ({ template, parameters }) => {
  return (
    <ManagerTemplatedFeedbackHeader
      template={template}
      parameters={parameters}
    />
  );
};

const ManagerTemplatedFeedbackHeader: React.FC<{
  template: IFeedbackTemplate;
  parameters: TemplateParameters;
}> = ({ template, parameters }) => {
  if (
    ['frt_tracker_feedback', 'frt_project_feedback'].includes(template.token)
  ) {
    return (
      <Column gap={24}>
        <Typography.Text>
          {runTemplate(
            '{requester} has requested your feedback on {receiver} based on the following topic.',
            parameters,
          )}
        </Typography.Text>
        <BlockQuote>
          <CommentOutlined
            style={{
              fontSize: 14,
              color: 'rgba(0,0,0,0.6)',
              marginRight: 12,
            }}
          />
          {runTemplate('{topic}', parameters)}
        </BlockQuote>
      </Column>
    );
  }

  return (
    <Typography.Text>
      {runTemplate(
        '{requester} has requested your feedback on {receiver}.',
        parameters,
      )}
    </Typography.Text>
  );
};
