/* eslint-disable @typescript-eslint/promise-function-async */
import { LOGIN_REDIRECT_PATH_KEY } from '@shared/constants';
import { Feature } from '@shared/features';
import { CUSTOMER_USER_ROLES, UserRole } from '@shared/types';
import { List1on1sPage } from '@web/1on1s/List1on1sPage';
import { View1on1Page } from '@web/1on1s/View1on1Page';
import { AlignmentPage } from '@web/alignment/AlignmentPage';
import { AnalyticsPage, AnalyticsTab } from '@web/analytics/AnalyticsPage';
import { AdminLoginPage } from '@web/auth/AdminLoginPage';
import { LoginPage } from '@web/auth/LoginPage';
import { LoginRedirect } from '@web/auth/LoginRedirect';
import { RolesRouteGuard } from '@web/auth/RolesRouteGuard';
import { useAuth } from '@web/auth/useAuth';
import { CheckinsPage } from '@web/checkins/CheckinsPage';
import { ViewCheckinPage } from '@web/checkins/ViewCheckinPage';
import localStorage from '@web/common/localStorage';
import { useFeature } from '@web/common/useFeature';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { AppConfigPage } from '@web/configuration/AppConfigPage';
import { GiveFeedbackPage } from '@web/feedback/GiveFeedbackPage';
import { RequestFeedbackPage } from '@web/feedback/RequestFeedbackPage';
import { ViewFeedbackPage } from '@web/feedback/ViewFeedbackPage';
import { ViewGivenFeedbackPage } from '@web/feedback/ViewGivenFeedbackPage';
import { ViewReceivedFeedbackPage } from '@web/feedback/ViewReceivedFeedbackPage';
import { ViewReviewCycleFeedbackPage } from '@web/feedback/ViewReviewCycleFeedbackPage';
import { ViewTaskFeedbackPage } from '@web/feedback/ViewTaskFeedbackPage';
import { GoalPage } from '@web/goals/GoalPage';
import { GoalsPage } from '@web/goals/GoalsPage';
import { NewGoalPage } from '@web/goals/NewGoalPage';
import { GoalListPage } from '@web/goals/lists/GoalListPage';
import { HomePage } from '@web/home/HomePage';
import { ProfilePage } from '@web/home/ProfilePage';
import { EditEntryPage } from '@web/journal/EditEntryPage';
import { JournalPage } from '@web/journal/JournalPage';
import { SuggestEntryPage } from '@web/journal/SuggestEntryPage';
import { ViewJournalEntryPage } from '@web/journal/ViewJournalEntryPage';
import { InstallGithubPage } from '@web/onboarding/InstallGithubPage';
import { InstallGithubSuccessPage } from '@web/onboarding/InstallGithubSuccessPage';
import ListSummariesPage from '@web/performance/ListSummariesPage';
import { NewSummaryPage } from '@web/performance/NewSummaryPage';
import { ViewSummaryPage } from '@web/performance/ViewSummaryPage';
import { EditReflectionPage } from '@web/reflections/EditReflectionPage';
import { ReflectionsPage } from '@web/reflections/ReflectionsPage';
import { ViewReflectionPage } from '@web/reflections/ViewReflectionPage';
import { ViewTagGroupPage } from '@web/reflections/tags/ViewTagGroupPage';
import { ViewTagGroupsPage } from '@web/reflections/tags/ViewTagGroupsPage';
import { RequestsPage } from '@web/requests/RequestsPage';
import IndividualReviewCyclePage from '@web/review-cycles/IndividualReviewCyclePage';
import ReviewCycleDashboardPage from '@web/review-cycles/ReviewCycleDashboardPage';
import { ReviewCycleTeamPage } from '@web/review-cycles/ReviewCycleTeamPage';
import ReviewCyclesPage from '@web/review-cycles/ReviewCyclesPage';
import { EditReviewPage } from '@web/review-cycles/reviews/EditReviewPage';
import { ViewReviewPage } from '@web/review-cycles/reviews/ViewReviewPage';
import { TakeSurveyPage } from '@web/surveys/TakeSurveyPage/TakeSurveyPage';
import { ViewSurveyPage } from '@web/surveys/ViewSurveyPage';
import { ViewSurveySummaryPage } from '@web/surveys/ViewSurveySummaryPage';
import SurveyCyclesPage from '@web/surveys/cycles/SurveyCyclesPage';
import UserSettingsPage from '@web/users/UserSettingsPage/UserSettingsPage';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NotFoundPage } from './NotFoundPage';
import { PageContent } from './Page';
import { InstallSlackPage, InstallSlackSuccessPage } from './lazy';
import { useResponsive } from './responsive';
import { Redirect } from './routing/Redirect';
import { adminRoutes } from './routing/adminRoutes';
import { internalRoutes } from './routing/internalRoutes';
import { teamRoutes } from './routing/teamRoutes';

export const AppRoutes: React.FC = () => {
  const {
    isLoading,
    isLoggedIn,
    isSuper,
    isOperations,
    isHrAdmin,
    managesPeople,
  } = useAuth();
  const { isMobile } = useResponsive();
  const { booleanValue: reviewCyclesEnabled } = useFeature(
    Feature.REVIEW_CYCLES,
  );
  const { booleanValue: reflectionsEnabled } = useFeature(Feature.REFLECTIONS);
  const { booleanValue: reflectionTagsEnabled } = useFeature(
    Feature.REFLECTION_TAGS_ENABLED,
  );

  if (isLoading) {
    return null;
  }

  if (!navigator.cookieEnabled) {
    return (
      <PageContent>
        <ErrorPageContent
          title="Cookies are disabled"
          subTitle={
            <Column>
              <Text>
                It looks like your browser does not have support for cookies.
              </Text>
              <Text>
                Cookies are needed in order to run this site correctly. Please
                try again in another browser or after enabling cookies.
              </Text>
            </Column>
          }
          extra={null}
        />
      </PageContent>
    );
  }

  if (isSuper || isOperations) {
    return (
      <React.Suspense>
        <Routes>
          <Route index element={<LoginRedirect />} />
          <Route path="redirect" element={<LoginRedirect />} />
          {internalRoutes()}
          <Route path="user">
            <Route path="settings" element={<LoginRedirect />} />
          </Route>
          <Route path="app/config" element={<AppConfigPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </React.Suspense>
    );
  }

  return (
    <React.Suspense>
      <Routes>
        <Route index element={isLoggedIn ? <LoginRedirect /> : <LoginPage />} />
        <Route path="redirect" element={<LoginRedirect />} />
        <Route
          path="home"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<HomePage />} />
        </Route>
        <Route
          path="analytics"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<Navigate to="overview" />} />
          <Route
            path="overview"
            element={<AnalyticsPage tab={AnalyticsTab.OVERVIEW} />}
          />
          <Route
            path="teams"
            element={<AnalyticsPage tab={AnalyticsTab.TEAMS} />}
          />
          <Route
            path="individuals"
            element={<AnalyticsPage tab={AnalyticsTab.INDIVIDUALS} />}
          />
        </Route>
        <Route
          path="journal"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<JournalPage />} />
          <Route path="new" element={<EditEntryPage />} />
          <Route path="suggest" element={<SuggestEntryPage />} />
          <Route path=":impactToken" element={<ViewJournalEntryPage />} />
          <Route path=":impactToken/edit" element={<EditEntryPage />} />
        </Route>
        <Route
          path="requests"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<RequestsPage />} />
          <Route
            path="tracker/:impactToken"
            element={
              <Redirect to={({ impactToken }) => `/journal/${impactToken}`} />
            }
          />
          <Route
            path="journal/:impactToken"
            element={<ViewJournalEntryPage />}
          />
          <Route path="given/:taskToken" element={<ViewTaskFeedbackPage />} />
        </Route>
        <Route
          path="slack"
          element={<RolesRouteGuard roles={[UserRole.ADMIN]} />}
        >
          <Route path="install" element={<InstallSlackPage />} />
          <Route path="install/success" element={<InstallSlackSuccessPage />} />
        </Route>
        <Route
          path="github"
          element={<RolesRouteGuard roles={[UserRole.ADMIN]} />}
        >
          <Route path="install" element={<InstallGithubPage />} />
          <Route
            path="install/success"
            element={<InstallGithubSuccessPage />}
          />
        </Route>
        <Route
          path="user"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route
            path="profile"
            element={<Navigate to="/user/settings" replace />}
          />
          <Route path="settings" element={<UserSettingsPage />} />
        </Route>
        <Route
          path="feedback"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<ViewReceivedFeedbackPage />} />
          <Route path="given" element={<ViewGivenFeedbackPage />} />
          <Route path="give" element={<GiveFeedbackPage />} />
          <Route path="request" element={<RequestFeedbackPage />} />
          <Route path=":feedbackToken/view" element={<ViewFeedbackPage />} />
        </Route>
        {managesPeople || isHrAdmin ? (
          <Route
            path="team"
            element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
          >
            {teamRoutes()}
          </Route>
        ) : null}
        <Route
          path="profile"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route path=":userToken" element={<ProfilePage />} />
        </Route>
        {!isMobile && adminRoutes()}
        {reflectionTagsEnabled && (
          <Route
            path="tags"
            element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
          >
            <Route index element={<ViewTagGroupsPage />} />
            <Route path="group/:token" element={<ViewTagGroupPage />} />
          </Route>
        )}
        {(reflectionsEnabled || reviewCyclesEnabled) && (
          <Route
            path="reflections"
            element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
          >
            {reflectionsEnabled && (
              <Route index element={<ReflectionsPage />} />
            )}
            <Route path=":reflectionToken" element={<ViewReflectionPage />} />
            <Route
              path=":reflectionToken/edit"
              element={<EditReflectionPage />}
            />
          </Route>
        )}
        <Route
          path="goals"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<GoalsPage />} />
          <Route path="archived" element={<GoalsPage archived />} />
          <Route path="new" element={<NewGoalPage />} />
          <Route path="lists">
            <Route path=":goalListToken" element={<GoalListPage />} />
          </Route>
          <Route path=":goalToken" element={<GoalPage />} />
        </Route>
        <Route
          path="1on1s"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<List1on1sPage />} />
          <Route path="archived" element={<List1on1sPage archived={true} />} />
          <Route
            path=":userToken1/:userToken2/:oneOnOneToken"
            element={<View1on1Page />}
          />
          <Route path=":userToken1/:userToken2" element={<View1on1Page />} />
        </Route>
        {reviewCyclesEnabled && (
          <>
            <Route
              path="review-cycles"
              element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
            >
              <Route index element={<Redirect to={() => '/cycles'} />} />
              <Route
                path=":reviewCycleToken"
                element={
                  <Redirect
                    to={({ reviewCycleToken }) => `/cycles/${reviewCycleToken}`}
                  />
                }
              />
              <Route
                path=":reviewCycleToken/users/:userToken"
                element={
                  <Redirect
                    to={({ reviewCycleToken, userToken }) =>
                      `/cycles/${reviewCycleToken}/users/${userToken}`
                    }
                  />
                }
              />
              <Route
                path=":reviewCycleToken/users/:userToken/feedback"
                element={
                  <Redirect
                    to={({ reviewCycleToken, userToken }) =>
                      `/cycles/${reviewCycleToken}/users/${userToken}/feedback`
                    }
                  />
                }
              />
            </Route>
            <Route
              path="cycles"
              element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
            >
              <Route index element={<ReviewCyclesPage />} />
              <Route
                path=":reviewCycleToken"
                element={<ReviewCycleDashboardPage />}
              />
              <Route
                path=":reviewCycleToken/users/:userToken"
                element={<IndividualReviewCyclePage />}
              />
              <Route
                path=":reviewCycleToken/teams/:userToken"
                element={<ReviewCycleTeamPage />}
              />
              <Route
                path=":reviewCycleToken/users/:userToken/feedback"
                element={<ViewReviewCycleFeedbackPage />}
              />
              <Route
                path=":reviewCycleToken/users/:userToken/reflection"
                element={<ViewReviewPage />}
              />
              <Route
                path=":reviewCycleToken/users/:userToken/reflection/edit"
                element={<EditReviewPage />}
              />
            </Route>
          </>
        )}
        <Route
          path="checkins"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<CheckinsPage />} />
          <Route path=":participantToken" element={<ViewCheckinPage />} />
        </Route>
        <Route
          path="surveys"
          element={<RolesRouteGuard roles={CUSTOMER_USER_ROLES} />}
        >
          <Route index element={<SurveyCyclesPage />} />
          <Route path=":surveyCycleToken" element={<TakeSurveyPage />} />
          <Route path=":surveyCycleToken/view" element={<ViewSurveyPage />} />
          <Route path=":surveyCycleToken/view" element={<ViewSurveyPage />} />
          <Route
            path=":surveyCycleToken/summary"
            element={<ViewSurveySummaryPage />}
          />
        </Route>
        <Route path="alignment">
          <Route index element={<AlignmentPage />} />
          <Route path=":userToken" element={<AlignmentPage />} />
        </Route>
        <Route path="performance">
          <Route index element={<ListSummariesPage />} />
          <Route path="new" element={<NewSummaryPage />} />
          <Route
            path=":performanceSummaryToken"
            element={<ViewSummaryPage />}
          />
        </Route>
        <Route
          path="/auth/admin/login"
          element={!isLoggedIn ? <AdminLoginPage /> : <LoginRedirect />}
        />
        <Route
          path="/auth/login"
          element={!isLoggedIn ? <LoginPage /> : <LoginRedirect />}
        />
        {!isLoggedIn ? (
          <Route path="*" element={<LoggedOutRedirect />} />
        ) : (
          <Route path="*" element={<NotFoundPage />} />
        )}
      </Routes>
    </React.Suspense>
  );
};

const LoggedOutRedirect: React.FC = () => {
  localStorage.setItem(
    LOGIN_REDIRECT_PATH_KEY,
    window.location.pathname + window.location.search,
  );
  return <Navigate to="/auth/login" replace />;
};
