import { PlayCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  GetBedrockConfigurationResponse,
  RunBedrockRequest,
} from '@shared/bedrock';
import { IUser } from '@shared/types';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Button, Descriptions, DescriptionsProps, message } from 'antd';
import pluralize from 'pluralize';
import * as React from 'react';

import { AddChannelsModal } from './AddChannelsModal';
import { SetupRunModal } from './SetupRunModal';
import { ShowChannelsButton } from './ShowChannelsButton';

export const ConfigurationSection: React.FC<{
  response: GetBedrockConfigurationResponse;
  userOptions: IUser[];
  deleteDisabled?: boolean;
  onAddChannels: (channelIds: string[]) => Promise<void>;
  onDeleteChannel: (channelId: string) => Promise<void>;
  onRun?: (data: RunBedrockRequest) => Promise<void>;
}> = ({
  response,
  userOptions,
  onAddChannels,
  onDeleteChannel,
  onRun,
  deleteDisabled,
}) => {
  const [showAddChannelsModal, setShowAddChannelsModal] = React.useState(false);
  const [showSetupRunModal, setShowSetupRunModal] = React.useState(false);

  const handleDeleteChannel = async (channelId: string) => {
    try {
      await onDeleteChannel(channelId);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleRun = async (data: RunBedrockRequest) => {
    try {
      await onRun(data);
      setShowSetupRunModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await onAddChannels(channelIds);
      setShowAddChannelsModal(false);
    } catch (error) {
      void message.error(channelIds);
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      key: 'enrollmentLimit',
      label: 'Enrollment Limit',
      children: <Text>{response.configuration.enrollmentLimit}</Text>,
    },
    {
      key: 'channelCount',
      label: 'Enrolled Channels',
      children: (
        <ShowChannelsButton
          channels={response.channels}
          onDelete={handleDeleteChannel}
          deleteDisabled={deleteDisabled}
        >
          View {response.channels.length}{' '}
          {pluralize('channel', response.channels.length)}
        </ShowChannelsButton>
      ),
    },
  ];

  return (
    <Column gap={12}>
      <Row>
        <Header2>Configuration</Header2>
        <GrowingSpacer />
        <Row gap={6}>
          {onRun && (
            <Button
              type="primary"
              onClick={() => {
                setShowSetupRunModal(true);
              }}
            >
              <PlayCircleOutlined /> Run
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              setShowAddChannelsModal(true);
            }}
          >
            <PlusOutlined /> Add Channels
          </Button>
        </Row>
      </Row>
      <Descriptions items={items} />
      {showAddChannelsModal && (
        <AddChannelsModal
          organizationToken={response.configuration.organizationToken}
          onAdd={handleAddChannels}
          onCancel={() => {
            setShowAddChannelsModal(false);
          }}
        />
      )}
      <SetupRunModal
        open={showSetupRunModal}
        onCancel={() => {
          setShowSetupRunModal(false);
        }}
        onSubmit={handleRun}
        userOptions={userOptions}
      />
    </Column>
  );
};
