import { RunBedrockRequest } from '@shared/bedrock';
import { Select } from 'antd';
import * as React from 'react';

interface MechanismOption {
  label: string;
  value: RunBedrockRequest['mechanism'];
}

export const SelectMechanism: React.FC<{
  disabled: boolean;
  onChange: (mechanism: RunBedrockRequest['mechanism']) => void;
}> = ({ disabled, onChange }) => {
  const options: MechanismOption[] = [
    {
      label: 'Journal Entries',
      value: 'journal',
    },
    {
      label: 'Direct Message Users',
      value: 'dm',
    },
  ];

  return <Select disabled={disabled} options={options} onChange={onChange} />;
};
