import { CommentOutlined } from '@ant-design/icons';
import { findTemplateByToken, sortResponses } from '@shared/feedbackTemplates';
import { IFeedback, IFeedbackRequest, UserMapItem } from '@shared/types';
import { isManagerOf } from '@shared/users';
import { useAuth } from '@web/auth/useAuth';
import { patch } from '@web/common/api';
import { BlockQuote } from '@web/components/BlockQuote';
import { Column } from '@web/components/layout';
import * as React from 'react';

import { Text } from '../../components/typography';
import { FeedbackItem } from './FeedbackItem';
import { UserFeedback } from './UserFeedback';

export const TemplatedUserFeedback: React.FC<{
  feedback: IFeedback;
  giver: UserMapItem;
  receiver: UserMapItem;
  requester?: UserMapItem;
}> = ({ feedback, giver, receiver, requester }) => {
  const { user } = useAuth();

  if (!feedback.feedbackRequest?.questionSetToken) {
    return null;
  }

  const isGiver = user.token === giver?.token;
  const isReceiver = user.token === receiver?.token;
  const isManager = isManagerOf(receiver, user.token);
  const orderedResponses = feedback.responses
    ? sortResponses(
        feedback.feedbackRequest.questionSetToken,
        feedback.responses,
      )
    : [];

  return (
    <UserFeedback giver={giver} receiver={receiver} date={feedback.updatedDate}>
      {requester && (
        <TemplatedFeedbackTitle
          feedbackRequest={feedback.feedbackRequest}
          requester={requester}
        />
      )}
      {orderedResponses.map((response) => {
        const handleSave =
          feedback.giverToken === user.token
            ? async (text: string) => {
                await patch(
                  `/feedback/${feedback.token}/question/${response.questionToken}`,
                  { text },
                );
              }
            : undefined;

        return (
          <FeedbackItem
            key={response.token}
            response={response}
            receiver={receiver}
            questionParameters={feedback.feedbackRequest?.templateParameters}
            onSave={handleSave}
            hideVisibility={!!feedback.reviewCycleToken}
            readonly={!isGiver && !isReceiver && !isManager}
          />
        );
      })}
    </UserFeedback>
  );
};

const TemplatedFeedbackTitle: React.FC<{
  feedbackRequest: IFeedbackRequest;
  requester: UserMapItem;
}> = ({ feedbackRequest, requester }) => {
  const { user } = useAuth();

  const template = findTemplateByToken(feedbackRequest.questionSetToken);
  if (!template || !!feedbackRequest.reviewCycleToken) {
    return null;
  }
  const isRequester = requester.token === user.token;
  switch (template.token) {
    case 'frt_tracker_feedback':
    case 'frt_project_feedback':
      return (
        <Column gap={6}>
          <Text>
            Feedback requested by {requester.name} {isRequester && '(you)'} on:
          </Text>
          <BlockQuote>
            <CommentOutlined
              style={{
                fontSize: 14,
                color: 'rgba(0,0,0,0.6)',
                marginRight: 12,
              }}
            />
            {feedbackRequest.templateParameters?.topic ?? ''}
          </BlockQuote>
        </Column>
      );
    default:
      return (
        <Text>
          Feedback requested by {requester.name}
          {isRequester && ' (you)'}
        </Text>
      );
  }
};
