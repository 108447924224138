import { PlusOutlined } from '@ant-design/icons';
import { BedrockReportType } from '@backend/bedrock/IBedrockReport';
import {
  createBedrockBatch,
  useBedrockBatches,
  useEnrolledBedrockUsersInternal,
} from '@client/BedrockConfigurationClient';
import {
  CreateBedrockBatchRequest,
  IBedrockBatchRow,
  RunBedrockRequest,
} from '@shared/bedrock';
import { formatLongDate } from '@shared/formatLongDate';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { useScheduledEvent } from '@web/surveys/cycles/useScheduledEvent';
import { Button, Checkbox, Modal, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BedrockTabs } from './BedrockTabs';
import { SelectReportType } from './SelectReportType';

export const BedrockBatchesPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: response, mutate: reloadBatches } =
    useBedrockBatches(organizationToken);
  const { data: enrollments } =
    useEnrolledBedrockUsersInternal(organizationToken);
  const navigate = useNavigate();

  const [showSetupRunModal, setShowSetupRunModal] = React.useState(false);

  const handleRun = async (data: RunBedrockRequest) => {
    await createBedrockBatch(organizationToken, data);
    void message.success('Prepared');
    void reloadBatches();
    setShowSetupRunModal(false);
  };

  const handleRowClick = (row: IBedrockBatchRow) => {
    navigate(`${row.token}`);
  };

  return (
    <PageContent>
      <PageHeader title="Bedrock" />
      <BedrockTabs />
      <Pane>
        <Column gap={24}>
          <Column gap={12}>
            <Row>
              <Header2>Batches</Header2>
              <GrowingSpacer />
              <Row gap={6}>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowSetupRunModal(true);
                  }}
                >
                  <PlusOutlined /> Create Batch
                </Button>
              </Row>
            </Row>
            <BedrockBatchesTable
              batches={response?.batches}
              onRowClick={handleRowClick}
            />
          </Column>
        </Column>
      </Pane>
      <CreateBatchModal
        open={showSetupRunModal}
        onCancel={() => {
          setShowSetupRunModal(false);
        }}
        onSubmit={handleRun}
        userOptions={enrollments?.map((enrollment) => enrollment.user)}
      />
    </PageContent>
  );
};

export const BedrockBatchesTable: React.FC<{
  batches?: IBedrockBatchRow[];
  onRowClick: (batch: IBedrockBatchRow) => void;
}> = ({ batches, onRowClick }) => {
  const columns: ColumnsType<IBedrockBatchRow> = [
    {
      dataIndex: 'createdDate',
      title: 'Created',
      render(createdDate: Date) {
        return formatLongDate(createdDate);
      },
    },
    {
      dataIndex: 'type',
      title: 'Type',
    },
    {
      dataIndex: ['jobData', 'oldest'],
      title: 'Start Date',
      render(oldest: string) {
        return formatLongDate(new Date(Math.floor(parseFloat(oldest) * 1000)));
      },
    },
    {
      dataIndex: ['jobData', 'latest'],
      title: 'End Date',
      render(latest: string) {
        return formatLongDate(new Date(Math.floor(parseFloat(latest) * 1000)));
      },
    },
    {
      dataIndex: 'lastRun',
      title: 'Last Run',
    },
    {
      dataIndex: 'totalRuns',
      title: 'Runs',
    },
    {
      dataIndex: 'totalSent',
      title: 'Runs Sent',
    },
  ];
  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={batches}
      loading={!batches}
      pagination={false}
      onRow={(batch: IBedrockBatchRow) => ({
        onClick: () => {
          onRowClick(batch);
        },
      })}
    />
  );
};

export const CreateBatchModal: React.FC<{
  open: boolean;
  onCancel: () => void;
  onSubmit: (data: CreateBedrockBatchRequest) => void;
  userOptions: IUser[];
}> = ({ open, onCancel, onSubmit, userOptions }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showTargetUsers, setShowTargetUsers] = React.useState(false);
  const [targetUserTokens, setTargetUserTokens] = React.useState<UserToken[]>(
    [],
  );
  const [type, setType] = React.useState<BedrockReportType | undefined>(
    undefined,
  );
  const startDate = useScheduledEvent();
  const endDate = useScheduledEvent();

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      const data: CreateBedrockBatchRequest = {
        startDate: new Date(`${startDate.date} ${startDate.hour}:00`),
        endDate: new Date(`${endDate.date} ${endDate.hour}:00`),
        targetUserTokens: showTargetUsers ? targetUserTokens : undefined,
        type,
      };
      await onSubmit(data);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Create Batch"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSubmitting}
      onCancel={onCancel}
      width="500px"
      okText="Create"
      okButtonProps={{
        disabled:
          isSubmitting ||
          !type ||
          (showTargetUsers && targetUserTokens.length === 0),
      }}
    >
      <Column gap={12}>
        <Text>
          A batch&apos;s date and users can not be changed after creation. If no
          users are targetted then all enrolled users will be included in the
          batch.
        </Text>
        <Column gap={6}>
          <Text>Report Type</Text>
          <SelectReportType disabled={isSubmitting} onChange={setType} />
        </Column>
        <Column gap={6}>
          <Text>Start Date</Text>
          <SelectDateHour
            disabled={isSubmitting}
            date={startDate.date}
            hour={startDate.hour}
            onChange={startDate.onChange}
            timezone={startDate.timezone}
          />
        </Column>
        <Column gap={6}>
          <Text>End Date</Text>
          <SelectDateHour
            disabled={isSubmitting}
            date={endDate.date}
            hour={endDate.hour}
            timezone={endDate.timezone}
            onChange={endDate.onChange}
          />
        </Column>
        <Column gap={6}>
          <Checkbox
            checked={showTargetUsers}
            onChange={() => {
              setShowTargetUsers(!showTargetUsers);
            }}
          >
            <Text>Target Users</Text>
          </Checkbox>
          {showTargetUsers && (
            <SelectUsers
              disabled={isSubmitting}
              initialUsers={userOptions.filter((u) =>
                targetUserTokens.includes(u.token),
              )}
              includeOnly={userOptions.map((u) => u.token)}
              onChange={(userTokens) => {
                setTargetUserTokens(userTokens);
              }}
            />
          )}
        </Column>
      </Column>
    </Modal>
  );
};
