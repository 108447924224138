import { DeleteOutlined } from '@ant-design/icons';
import { IChannelInfo } from '@shared/bedrock';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Modal, message } from 'antd';
import * as React from 'react';

export const ShowChannelsButton: React.FC<{
  children?: React.ReactNode;
  channels: IChannelInfo[];
  onDelete: (channelId: string) => Promise<void>;
  deleteDisabled: boolean;
}> = ({ children, channels, onDelete, deleteDisabled }) => {
  const [open, setOpen] = React.useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const handleDeleteChannel = async (channelId: string) => {
    try {
      await onDelete(channelId);
    } catch (error) {
      void message.error('Error');
    }
  };
  return (
    <Column>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </Button>
      <Modal
        open={open}
        title="Channels"
        onCancel={closeModal}
        onOk={closeModal}
        width="400px"
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
      >
        <Column gap={3}>
          {channels.map((channel) => (
            <Row key={channel.id} gap={6}>
              <DeleteOutlined
                disabled={deleteDisabled || open}
                onClick={async () => {
                  await handleDeleteChannel(channel.id);
                }}
              />
              <Text>#{channel.name}</Text>
            </Row>
          ))}
        </Column>
      </Modal>
    </Column>
  );
};
