import { PlusOutlined } from '@ant-design/icons';
import {
  addBedrockChannelsAdmin,
  deleteBedrockChannelAdmin,
  deleteEnrolledBedrockUser,
  enrollBedrockUser,
  useBedrockConfigurationAdmin,
  useEnrolledBedrockUsers,
} from '@client/BedrockConfigurationClient';
import { update as updateUser } from '@client/UsersClient';
import { IBedrockEnrollment } from '@shared/bedrock';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { EnrollUsersModal } from '@web/bedrock/EnrollUsersModal';
import { EnrollmentsTable } from '@web/bedrock/EnrollmentsTable';
import { AddChannelsModal } from '@web/bedrock/internal/AddChannelsModal';
import { ConfigurationSection } from '@web/bedrock/internal/ConfigurationSection';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Button, Skeleton, message } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { AdminPageContent } from './AdminPageContent';

export const AdminBedrockPage: React.FC = () => {
  const { user } = useAuth();
  const { data: response, mutate: reloadConfiguration } =
    useBedrockConfigurationAdmin();
  const { data: enrollments, mutate: reloadEnrollments } =
    useEnrolledBedrockUsers();
  const [isDeletingChannel, setIsDeletingChannel] = React.useState(false);
  const [showAddChannelsModal, setShowAddChannelsModal] = React.useState(false);
  const [showEnrollModal, setShowEnrollModal] = React.useState(false);

  const handleEnroll = async (userToken: UserToken) => {
    try {
      await enrollBedrockUser(userToken);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to enroll user');
    } finally {
      setShowEnrollModal(false);
    }
  };

  const handleDelete = async (enrollment: IBedrockEnrollment) => {
    try {
      await deleteEnrolledBedrockUser(enrollment.user.token);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove user');
    }
  };

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await addBedrockChannelsAdmin(channelIds);
      await reloadConfiguration();
      void message.success('Success');
      setShowAddChannelsModal(false);
    } catch (error) {
      void message.error('Failed to add channels');
    }
  };

  const handleDeleteChannel = async (channelId: string) => {
    setIsDeletingChannel(true);
    try {
      await deleteBedrockChannelAdmin(channelId);
      await reloadConfiguration();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove channel');
    } finally {
      setIsDeletingChannel(false);
    }
  };

  if (!user || !response) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }
  return (
    <AdminPageContent>
      <Column gap={24}>
        <ConfigurationSection
          response={response}
          userOptions={enrollments?.map((enrollment) => enrollment.user) ?? []}
          onAddChannels={handleAddChannels}
          onDeleteChannel={handleDeleteChannel}
          deleteDisabled={isDeletingChannel}
        />
        <Column gap={12}>
          <Row gap={6}>
            <GrowingSpacer />
            <Button
              type="primary"
              onClick={() => {
                setShowEnrollModal(true);
              }}
              style={{ maxWidth: 200 }}
            >
              <PlusOutlined /> Enroll Users
            </Button>
          </Row>
        </Column>
      </Column>
      {enrollments ? (
        <EnrollmentsTable
          enrollments={enrollments}
          onDelete={handleDelete}
          onEditGithub={async (userToken: UserToken, githubLogin: string) => {
            await updateUser(userToken, {
              githubLogin: isEmpty(githubLogin) ? null : githubLogin.trim(),
            });
          }}
        />
      ) : (
        <Skeleton />
      )}
      {showEnrollModal && (
        <EnrollUsersModal
          organizationToken={user.organizationToken}
          omitUserTokens={enrollments?.map(
            (enrollment) => enrollment.user.token,
          )}
          onCancel={() => {
            setShowEnrollModal(false);
          }}
          onEnroll={handleEnroll}
        />
      )}
      {showAddChannelsModal && (
        <AddChannelsModal
          organizationToken={response.configuration.organizationToken}
          onAdd={handleAddChannels}
          onCancel={() => {
            setShowAddChannelsModal(false);
          }}
        />
      )}
    </AdminPageContent>
  );
};
