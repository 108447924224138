import { IAlignmentSettings } from './alignment';
import { IFeatureOverride } from './features';
import { FeedbackTemplateToken } from './feedbackTemplates';
import { IImpact, ISuggestedEntry, ImpactToken } from './impacts';
import { IQuestionResponse } from './question_response';
import { IQuestion, QuestionToken } from './questions';
import { EntityReactionSummaryMap } from './reactions';
import { IReflection, ReflectionToken } from './reflections';
import { IReviewCycle, ReviewCycleToken } from './review-cycles';
import { ISurveyCycle, SurveyCycleToken } from './surveys';
import { TeamSource } from './teams';
import { SupportedTimezone } from './timezones';

export type ContentType =
  | 'text/plain'
  | 'text/html'
  | 'text/markdown'
  | 'application/json';

export enum UserRole {
  SUPER = 'SUPER',
  OPERATIONS = 'OPERATIONS',
  ADMIN = 'ADMIN',
  HR_ADMIN = 'HR_ADMIN',
  HRBP = 'HRBP',
  ENROLLED = 'ENROLLED',
  EMPLOYEE = 'EMPLOYEE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}

export const UserRoleLabels: Record<UserRole, string> = {
  [UserRole.SUPER]: 'Super',
  [UserRole.OPERATIONS]: 'Operations',
  [UserRole.ADMIN]: 'IT Admin',
  [UserRole.HR_ADMIN]: 'HR Admin',
  [UserRole.HRBP]: 'HRBP',
  [UserRole.ENROLLED]: 'Enrolled',
  [UserRole.EMPLOYEE]: 'Employee',
  [UserRole.INACTIVE]: 'Inactive',
  [UserRole.INVITED]: 'Invited',
};

export const CUSTOMER_USER_ROLES = [
  UserRole.ADMIN,
  UserRole.ENROLLED,
  UserRole.EMPLOYEE,
  UserRole.HR_ADMIN,
  UserRole.HRBP,
];

export const INACTIVE_USER_ROLES = [UserRole.INACTIVE, UserRole.INVITED];

export const CUSTOMER_ADMIN_ROLES = [UserRole.ADMIN, UserRole.HR_ADMIN];

export const ENROLLED_CUSTOMER_USER_ROLES = [
  UserRole.ENROLLED,
  UserRole.ADMIN,
  UserRole.HR_ADMIN,
  UserRole.HRBP,
];
export const FLINT_INTERNAL_USER_ROLES = [UserRole.SUPER, UserRole.OPERATIONS];

export type UserToken = `u_${string}`;

export const FLINT_USER: UserMapItem = {
  token: 'u_flint',
  name: 'Flint',
  email: 'noreply@flint.cc',
  role: UserRole.ENROLLED,
};

export const GITHUB_USER: UserMapItem = {
  token: 'u_github',
  name: 'Github',
  email: 'noreply@flint.cc',
  role: UserRole.ENROLLED,
};

export function isUserToken(token: string | undefined): token is UserToken {
  return !!token?.startsWith('u_');
}

export function isImpactToken(token: string | undefined): token is ImpactToken {
  return !!token?.startsWith('i_');
}

export function isOrganizationToken(
  token: string | undefined,
): token is OrganizationToken {
  return !!token?.startsWith('o_');
}

export function assertUserToken(
  token: string | undefined,
): asserts token is UserToken {
  if (!isUserToken(token)) {
    throw new Error('Token is not a UserToken');
  }
}

export type WithOrganizationToken<
  T extends { organizationToken: OrganizationToken },
> = Partial<T> & Pick<T, 'organizationToken'>;

export interface IEntity {
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}

export interface IUser extends IEntity {
  token: UserToken;
  name: string;
  source: UserSource;
  email: string;
  googleEmail?: string;
  /** @deprecated Use managerTokens instead */
  managerToken?: UserToken;
  managerTokens?: UserToken[];
  teams?: ITeam[];
  managingTeams?: ITeam[];
  organizationToken?: OrganizationToken;
  organization?: IOrganization;
  password: string;
  role: UserRole;
  firstLoginDate?: Date;
  firstEntryCreatedDate?: Date;
  slackId?: string;
  slackImageUrl?: string;
  githubLogin?: string;
  sendReportImpactCreatedNotifications?: boolean;
  sendReportImpactInactivityNotifications?: boolean;
  sendImpactFeedbackNotifications?: boolean;
  sendReportImpactFeedbackNotifications?: boolean;
  sendManagerWeeklyImpactTrackerSummary?: boolean;
  sendCreateImpactReminderNotifications?: boolean;
  disableNotifications: boolean;
  showSlackJournalEntryTour?: boolean;
  showSlackSuggestedEntryTour?: boolean;
}

export type IOrganizationMember = Pick<
  IUser,
  'token' | 'name' | 'organizationToken' | 'managerTokens' | 'email' | 'role'
>;

export enum UserSource {
  MANUAL = 'MANUAL',
  // Right now SLACK means 'managed by Flint', including
  // by workday. This may change by the time the workday integration launches.
  SLACK = 'SLACK',
}

export interface ISession {
  user?: IUser;
  organization?: IOrganization;
  impersonator?: IUser;
  features?: IFeatureOverride[];
  managesManagers?: boolean;
  managesPeople?: boolean;
  managers?: IUser[];
  needsRefresh?: boolean;
}

export interface ISearchQuery {
  skip: number;
  limit: number;
}

export interface ISearchResults<T> {
  results: T[];
  total: number;
  userMap?: UserMap;
  reactionSummaryMap?: EntityReactionSummaryMap;
}

export type FeedbackToken = `f_${string}`;

export enum FeedbackType {
  FEEDBACK = 'FEEDBACK', // a general bucket for open-ended feedback
  TEMPLATE = 'TEMPLATE',
  OPEN_ENDED = 'OPEN_ENDED',
}

export type FeedbackReceiverToken = OrganizationToken | UserToken | string;

export interface IFeedback extends IEntity {
  token: FeedbackToken;
  type: FeedbackType;
  visibility: FeedbackVisibility;
  giverToken: UserToken;
  receiverToken: UserToken;
  organizationToken: OrganizationToken;
  text?: string;
  feedbackRequest?: IFeedbackRequest;
  questionToken?: QuestionToken;
  templateToken?: FeedbackTemplateToken;
  entityToken?: string;
  impact?: IImpact;
  responses?: IQuestionResponse[];
  reviewCycle?: IReviewCycle;
  reviewCycleToken?: ReviewCycleToken;
  isDraft: boolean;
}

export type FeedbackRequestToken = `fr_${string}`;

export interface IFeedbackRequest {
  type: FeedbackType;
  organizationToken: OrganizationToken;
  token: FeedbackRequestToken;
  giverToken: UserToken;
  receiverToken: UserToken;
  requesterToken?: UserToken;
  text?: string;
  questionToken?: QuestionToken;
  question?: IQuestion;
  questionPosition?: number;
  questionSetToken?: FeedbackTemplateToken;
  templateParameters?: Record<string, string>;
  visibility: FeedbackVisibility;
  feedback?: IFeedback;
  createdDate: Date;
  updatedDate: Date;
  task?: ITask;
  taskToken?: TaskToken;
  reviewCycle?: IReviewCycle;
  reviewCycleToken?: ReviewCycleToken;
  context?: string;
}

export interface IFeedbackRequestParametersResponse {
  templateParameters: Record<string, string>;
}

export interface IFeedbackResponse {
  feedback: IFeedback[];
  userTokenNameMap: UserTokenNameMap;
  userMap: UserMap;
}

export interface IDraftResponse {
  token: string;
  entityToken: string;
  organizationToken: OrganizationToken;
  questionToken?: QuestionToken;
  text: string;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}

export interface IRecentFeedbackResponse {
  feedback: IFeedback[];
  given: IFeedback[];
  userTokenNameMap: UserTokenNameMap;
  userMap: UserMap;
  reactionSummaryMap: EntityReactionSummaryMap;
}

export interface ITasksResponse {
  tasks: ITask[];
  userMap: UserMap;
}

export enum TaskFilter {
  ALL = 'all',
  OPEN = 'open',
  COMPLETED = 'completed',
  DECLINED = 'declined',
}

export enum FeedbackVisibility {
  RECEIVER_MANAGER = 'RECEIVER_MANAGER',
  MANAGER_ONLY = 'MANAGER_ONLY',
  RECEIVER_ONLY = 'RECEIVER_ONLY',
  PUBLIC = 'PUBLIC',
  SYSTEM = 'SYSTEM',
}

export const ManagerVisibleFeedbackVisibility: FeedbackVisibility[] = [
  FeedbackVisibility.RECEIVER_MANAGER,
  FeedbackVisibility.PUBLIC,
  FeedbackVisibility.MANAGER_ONLY,
];

export const ReceiverVisibleFeedbackVisibility: FeedbackVisibility[] = [
  FeedbackVisibility.RECEIVER_MANAGER,
  FeedbackVisibility.PUBLIC,
  FeedbackVisibility.RECEIVER_ONLY,
];

export const FeedbackVisibilityLabels: Record<FeedbackVisibility, string> = {
  [FeedbackVisibility.RECEIVER_MANAGER]: 'Recipient and their manager',
  [FeedbackVisibility.MANAGER_ONLY]: 'Manager only',
  [FeedbackVisibility.PUBLIC]: 'Publicly visible',
  [FeedbackVisibility.RECEIVER_ONLY]: 'Recipient only',
  [FeedbackVisibility.SYSTEM]: 'Not visible',
};

export type OrganizationToken = `o_${string}`;

export interface IOrganization extends IAlignmentSettings {
  token: OrganizationToken;
  name: string;
  debugChannel?: string;
  /** No notifications will be sent out on a scheduled basis unless this is true */
  createdDate: Date;
  updatedDate: Date;
  activationDate: Date;
  emailWhitelist?: string[];
  sandboxMode: boolean;
  githubFeatureActive: boolean;
  journalNotificationsActive: boolean;
  defaultTimezone: SupportedTimezone;
  opsCanImpersonate: boolean;
}

export type UserMapItem = Pick<
  IUser,
  | 'name'
  | 'email'
  | 'slackId'
  | 'token'
  | 'managerToken'
  | 'managerTokens'
  | 'githubLogin'
  | 'slackImageUrl'
  | 'organizationToken'
  | 'deletedDate'
  | 'role'
>;
export type UserMap = Record<UserToken, UserMapItem>;
export type UserTokenNameMap = Record<UserToken, string>;

export type TeamToken = `tm_${string}`;

export interface ITeam {
  token: TeamToken;
  organizationToken: OrganizationToken;
  name?: string;
  manager?: IUser;
  managerToken: UserToken;
  hrbp?: IUser;
  members: IUser[];
  source: TeamSource;
}

export enum ExistingTeamResolution {
  MOVE_TEAMS = 'MOVE_TEAMS',
  ADD_MANAGER = 'ADD_MANAGER',
}

export type TaskToken = `t_${string}`;

export enum TaskType {
  FEEDBACK_REQUEST = 'FEEDBACK_REQUEST',
  IMPACT_ENTRY_FEEDBACK = 'IMPACT_ENTRY_FEEDBACK',
  SUGGESTED_ENTRY = 'SUGGESTED_ENTRY',
  REFLECTION = 'REFLECTION',
  SURVEY = 'SURVEY',
}

export interface ITask extends IEntity {
  token: TaskToken;
  organizationToken: OrganizationToken;
  type: TaskType;
  dueDate: Date;
  firstReminderSentDate?: Date;
  secondReminderSentDate?: Date;
  completedDate?: Date;
  declinedDate?: Date;
  assignedToken: UserToken;
  title?: string;
  feedbackRequests: IFeedbackRequest[];
  impact?: IImpact;
  suggestedEntry?: ISuggestedEntry;
  reflection?: IReflection;
  reflectionToken?: ReflectionToken;
  reviewCycle?: IReviewCycle;
  reviewCycleToken?: ReviewCycleToken;
  surveyCycle?: ISurveyCycle;
  surveyCycleToken?: SurveyCycleToken;
}

export interface TaskResult {
  task: ITask;
  userMap: UserMap;
}

export interface TasksResults {
  tasks: ITask[];
  userTokenNameMap: UserTokenNameMap;
}

export interface TaskResults {
  task: ITask;
  userTokenNameMap: UserTokenNameMap;
}

export interface IUserMaps {
  users: Record<UserToken, IUser>;
  // peer givers are the users who will be requested to give feedback to a user
  peerGivers: Record<UserToken, IUser[]>;
  // peer receivers are the users who will receive feedback from a user
  peerReceivers: Record<UserToken, IUser[]>;
  teammates: Record<UserToken, IUser[]>;
  managers: Record<UserToken, IUser>;
  reports: Record<UserToken, IUser[]>;
}

export interface ISlackAccess {
  slackId: string;
  botUserId?: string;
  scope?: string;
  teamName?: string;
  organizationToken?: OrganizationToken;
  createdDate: Date;
  updatedDate: Date;
}

export interface CreateSandboxRequest {
  name: string;
  managerName: string;
  reportName: string;
}

export interface UpdateFeedbackResponse {
  feedback?: IFeedback;
}
