import {
  FeedbackType,
  IFeedbackRequest,
  IUser,
  TaskResult,
} from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Max } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Button, Skeleton } from 'antd';
import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { GiveBasicFeedbackForm } from './GiveBasicFeedbackForm';
import { GiveTemplatedFeedbackForm } from './GiveTemplatedFeedbackForm';

export const GiveFeedbackPage: React.FC = () => {
  const { reloadOutstandingTaskCount } = useOutstandingCount();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: taskResult, error } = useApi<TaskResult, ServerResponseError>(
    searchParams.has('t') ? `/tasks/${searchParams.get('t')}` : null,
  );
  const userToken = searchParams.get('user');
  const { data: receiver } = useApi<IUser>(
    userToken ? `/users/${userToken}` : null,
  );

  if (!taskResult && searchParams.has('t') && !error) {
    return (
      <PageContent>
        <PageHeader
          title="Give feedback"
          mobileTitle="Give feedback"
          navigateBack
          defaultNavigateBackTo="/feedback"
        />
        <Skeleton />
      </PageContent>
    );
  }

  if (error) {
    return (
      <ErrorPageContent
        statusCode={error.statusCode}
        title={
          error.statusCode === 404 ? 'This page does not exist' : undefined
        }
        extra={
          <Link to="/feedback">
            <Button>Back to Feedback</Button>
          </Link>
        }
      />
    );
  }

  const { task, userMap } = taskResult ?? {};

  if (task?.completedDate) {
    navigate(`/requests/given/${task.token}`, { replace: true });
  }

  const handleSuccess = () => {
    navigate('/feedback/given', { replace: true });
    void reloadOutstandingTaskCount();
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const feedbackRequest = task?.feedbackRequests?.[0];
  const feedbackRequestReceiver = feedbackRequest
    ? userMap?.[feedbackRequest.receiverToken]
    : null;
  return (
    <PageContent>
      <PageHeader
        title="Give feedback"
        mobileTitle="Give feedback"
        navigateBack
        defaultNavigateBackTo="/feedback"
      />
      <Column gap={12}>
        {feedbackRequest && feedbackRequestReceiver ? (
          <FeedbackRequestedContext
            receiverName={feedbackRequestReceiver.name}
            feedbackRequest={feedbackRequest}
          />
        ) : undefined}
        <Pane>
          <Max width="800px">
            {userToken && !receiver ? (
              <Skeleton />
            ) : feedbackRequest &&
              feedbackRequest.type === FeedbackType.TEMPLATE ? (
              <GiveTemplatedFeedbackForm
                receiver={userMap?.[feedbackRequest?.receiverToken]}
                user={user}
                task={task}
                feedbackRequest={feedbackRequest}
                onSuccess={handleSuccess}
                onCancel={handleCancel}
              />
            ) : (
              <GiveBasicFeedbackForm
                userMap={userMap}
                user={user}
                task={task}
                feedbackRequest={feedbackRequest}
                onSuccess={handleSuccess}
                onCancel={handleCancel}
                receiver={receiver}
              />
            )}
          </Max>
        </Pane>
      </Column>
    </PageContent>
  );
};

export const FeedbackRequestedContext: React.FC<{
  receiverName: string;
  feedbackRequest: IFeedbackRequest;
}> = ({ receiverName, feedbackRequest }) => {
  const { context } = feedbackRequest;
  const hasContext = !!context?.trim().length;
  if (!hasContext) {
    return <></>;
  }

  return (
    <Pane>
      <Max width="800px">
        <Column gap={24}>
          <Text>
            {receiverName} has requested your feedback. All responses will be
            visible to both {receiverName} and their manager.
          </Text>
          {hasContext && (
            <Column gap={6}>
              <Header3>
                They would love feedback about their work in this area
              </Header3>
              <Text>{context?.trim()}</Text>
            </Column>
          )}
        </Column>
      </Max>
    </Pane>
  );
};
