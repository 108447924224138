import { PlusOutlined } from '@ant-design/icons';
import {
  addBedrockChannelsInternal,
  deleteBedrockChannelInternal,
  deleteEnrolledBedrockUserInternal,
  enrollBedrockUsersInternal,
  runBedrock,
  useBedrockConfigurationInternal,
  useEnrolledBedrockUsersInternal,
} from '@client/BedrockConfigurationClient';
import { update as updateUser } from '@client/UsersClient';
import { IBedrockEnrollment, RunBedrockRequest } from '@shared/bedrock';
import { OrganizationToken, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EnrollUsersModal } from '../EnrollUsersModal';
import { EnrollmentsTable } from '../EnrollmentsTable';
import { AddChannelsModal } from './AddChannelsModal';
import { BedrockTabs } from './BedrockTabs';
import { ConfigurationSection } from './ConfigurationSection';
import { SetupRunModal } from './SetupRunModal';

export const InternalBedrockPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: response, mutate: reloadConfiguration } =
    useBedrockConfigurationInternal(organizationToken);
  const { data: enrollments, mutate: reloadEnrollments } =
    useEnrolledBedrockUsersInternal(organizationToken);
  const [isDeletingChannel, setIsDeletingChannel] = React.useState(false);
  const [showEnrollModal, setShowEnrollModal] = React.useState(false);
  const [showAddChannelsModal, setShowAddChannelsModal] = React.useState(false);
  const [showSetupRunModal, setShowSetupRunModal] = React.useState(false);

  if (!response || !enrollments) {
    return (
      <PageContent>
        <PageHeader title="Bedrock" />
        <BedrockTabs />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const handleEnroll = async (userToken: UserToken) => {
    try {
      await enrollBedrockUsersInternal(organizationToken, userToken);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to enroll user');
    } finally {
      setShowEnrollModal(false);
    }
  };

  const handleDelete = async (enrollment: IBedrockEnrollment) => {
    try {
      await deleteEnrolledBedrockUserInternal(
        enrollment.user.token,
        organizationToken,
      );
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove user');
    }
  };

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await addBedrockChannelsInternal(organizationToken, channelIds);
      await reloadConfiguration();
      void message.success('Success');
      setShowAddChannelsModal(false);
    } catch (error) {
      void message.error('Failed to add channels');
    }
  };

  const handleDeleteChannel = async (channelId: string) => {
    setIsDeletingChannel(true);
    try {
      await deleteBedrockChannelInternal(organizationToken, channelId);
      await reloadConfiguration();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove channel');
    } finally {
      setIsDeletingChannel(false);
    }
  };

  const handleRun = async (data: RunBedrockRequest) => {
    await runBedrock(organizationToken, data);
    void message.success('Scheduled');
    setShowSetupRunModal(false);
  };

  return (
    <PageContent>
      <PageHeader title="Bedrock" />
      <BedrockTabs />
      <Pane>
        <Column gap={24}>
          <ConfigurationSection
            response={response}
            userOptions={enrollments.map((enrollment) => enrollment.user)}
            onAddChannels={handleAddChannels}
            onDeleteChannel={handleDeleteChannel}
            onRun={handleRun}
            deleteDisabled={isDeletingChannel}
          />
          <Column gap={12}>
            <Row>
              <Header2>Enrollment</Header2>
              <GrowingSpacer />
              <Button
                type="primary"
                onClick={() => {
                  setShowEnrollModal(true);
                }}
                style={{ maxWidth: 200 }}
              >
                <PlusOutlined /> Enroll Users
              </Button>
            </Row>
            {enrollments ? (
              <EnrollmentsTable
                enrollments={enrollments}
                onDelete={handleDelete}
                onEditGithub={async (
                  userToken: UserToken,
                  githubLogin: string,
                ) => {
                  await updateUser(userToken, {
                    githubLogin: isEmpty(githubLogin)
                      ? null
                      : githubLogin.trim(),
                  });
                }}
              />
            ) : (
              <Skeleton />
            )}
          </Column>
        </Column>
      </Pane>
      {showEnrollModal && (
        <EnrollUsersModal
          organizationToken={organizationToken}
          omitUserTokens={enrollments?.map(
            (enrollment) => enrollment.user.token,
          )}
          onCancel={() => {
            setShowEnrollModal(false);
          }}
          onEnroll={handleEnroll}
        />
      )}
      {showAddChannelsModal && (
        <AddChannelsModal
          organizationToken={organizationToken}
          onAdd={handleAddChannels}
          onCancel={() => {
            setShowAddChannelsModal(false);
          }}
        />
      )}
      <SetupRunModal
        open={showSetupRunModal}
        onCancel={() => {
          setShowSetupRunModal(false);
        }}
        onSubmit={handleRun}
        userOptions={enrollments.map((enrollment) => enrollment.user)}
      />
    </PageContent>
  );
};
